@charset "UTF-8";

article, aside, figcaption, figure,
header, main, nav, footer, section {
    display: block;
}
audio, canvas, video  { display: inline-block; }
audio:not([controls]) { display: none; height: 0; }

/* basic */
html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
body, header, footer, section { margin: 0; }
// a:focus                       { outline: thin dotted; }
// a:active, a:hover             { outline: 0; }

/* text */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: normal;
}
dl, dd, p { margin: 0; }
ul, ol    { margin: 0; padding: 0; }
li        { list-style-type: none; }
b, strong { font-weight: bold; }

/* code/pre */
code, pre { font-family: monospace, serif; font-size: 1em; }
pre       { white-space: pre-wrap; }

/* sub/sup */
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }

/* visual */
img            { border: 0; vertical-align: top; }
img[usemap]    { height: auto; max-width: 100%; width: auto; }
svg:not(:root) { overflow: hidden; }
figure         { margin: 0; }

/* other */
hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
mark {
    background: #ff0;
    color: #000;
}

/* form */
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
legend { border: 0; padding: 0; }
button, input, select, textarea {
    /*font-family: inherit;*/
    font-size: 100%;
    margin: 0;
}
button, input  { line-height: normal; }
button, select { text-transform: none; }
button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled], html input[disabled] {
    cursor: default;
}
input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}

/* table */
table {
    border-collapse: collapse;
    border-spacing: 0;
}
th { text-align: left; }
