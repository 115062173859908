@charset "UTF-8";

/* =============== variable =============== */
$baseFontSize: 16;
$colorBodyBG: #fff;
$colorPink: #f254a1;
$colorDeepPink: #ad004a;
$colorLightPink: #fbe1ee;
$content-width: 1050px;
$breakpoint-small: 480px;
$breakpoint-custom: 600px;
$breakpoint-medium: 768px;
$breakpoint-large: 900px;


/* =============== mixin =============== */
@mixin font-pixel-to-rem($font-size) {
  font-size: ($font-size / $baseFontSize) * 1rem;
}

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin min-screen($break-point) {
  @media screen and (min-width: ($break-point + 1px)) {
    @content;
  }
}
@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: ($break-point-min + 1px)) and (max-width: $break-point-max) {
    @content;
  }
}


/* =============== extend =============== */
%clearfix {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

%layoutCenter {
  margin-right: auto;
  margin-left: auto;
  max-width: $content-width;

  @include max-screen($content-width) {
    padding-right: 10px;
    padding-left: 10px;
  }
}


/* =============== base =============== */
body {
  position: relative;
  background-color: $colorBodyBG;
  color: #000;
  font-family: 'Rounded Mplus 1c', 'Hiragino Kaku Gothic Pro', Meiryo, sans-serif;
  line-height: 1.5;

  * {
		box-sizing: border-box;
	}
}

// link
a {
  color: $colorDeepPink;
  text-decoration: underline;

  &:hover, &:active {
    text-decoration: none;
  }
}

// img
img {
  max-width: 100%;
}
