@charset "UTF-8";

/* =============== header =============== */
// ヘッダー
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  z-index: 100;

  .inner {
    @extend %layoutCenter;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // ロゴ
  .logo a {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
  }
  .logo .kana {
    margin: 0 0 10px 10px;
    color: darken(#de337e, 7);
    @include max-screen($breakpoint-small) {
      margin-bottom: 5px;
    }
  }
  .logo img {
    height: 55px;

    @include max-screen($breakpoint-small) {
      height: 40px;
    }
  }

  // スマホメニューボタン
  .spmenu-toggle {
    display: block;
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 25px 0 0;
    width: 3.4em;
    height: 50px;
    border: 0;
    background: $colorBodyBG;
    transition: all .4s;

    @include min-screen($breakpoint-large) {
      display: none;
    }
    @include max-screen($breakpoint-small) {
      top: 7px;
      right: 7px;
    }
    @include max-screen(320px) {
      top: 5px;
      right: 5px;
    }

    // ハンバーガーアイコン
    .top, .middle, .bottom {
      position: absolute;
      top: 14px;
      left: .8em;
      width: 1.8em;
      height: 3px;
      background-color: #000;
      border-radius: 4px;
      transition: all .4s;
      transform: rotate(0deg);
    }
    .top {
      transform: translateY(-8px);
    }
    .bottom {
      transform: translateY(8px);
    }
    &.active {
      .top {
        transform: rotate(-45deg) translateY(0);
      }
      .middle {
        background-color: rgba(255,255,255,0);
      }
      .bottom {
        transform: rotate(45deg) translateY(0);
      }
    }

    // ラベル
    .label {
      @include font-pixel-to-rem(12);
      display: block;
      color: #000;
      line-height: 1em;
    }
  }
}

// グローバルナビ
#nav-global {
  a {
    display: block;
    color: #000;
    text-decoration: none;
  }

  // FB
  .fb {
    position: absolute;
    top: 20px;
    right: 0;

    @include max-screen(900px) {
      right: 75px;
    }
    @include max-screen($breakpoint-small) {
      top: 12px;
    }

    img {
      width: 36px;
    }
  }

  // PC
  @include min-screen($breakpoint-large) {
    position: absolute;
    top: 0;
    right: 0;

    .menus {
      display: flex;
      padding-right: 60px;
      height: 75px;

      li {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 12px;
          width: 100%;
          height: 100%;

          &.active, &:hover, &:active, &:focus  {
            color: $colorDeepPink;
          }
        }
      }
    }
  }

  // MOBILE
  @include max-screen($breakpoint-large) {
    .menus {
      margin-top: 10px;

      > li:last-child a {
        border-bottom: 1px solid #ddd;
      }
      a {
        padding: 8px 10px;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        background-color: #eee;

        &::before {
          margin-right: 8px;
          content: '>';
        }
        &:hover, &:active {
          background-color: $colorPink;
          color: #fff;
        }
      }
    }
  }
}

#topicpath {
  @include font-pixel-to-rem(14);
  line-height: 1;
  background-color: #000;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }
  em {
    font-style: normal;
  }
}


/* =============== main =============== */
#main {
  margin-top: 110px;
  .home & {
    margin-top: 75px;
  }

  @include max-screen($breakpoint-small) {
    margin-top: 95px;
    .home & {
      margin-top: 60px;
    }
  }
}


/* =============== footer =============== */
#footer {
  .nav {
    @extend %layoutCenter;
    position: relative;
    margin-top: 45px;
    margin-bottom: 25px;

    @include max-screen($content-width) {
      ul {
        padding-right: 60px;
      }
    }
    li {
      @include font-pixel-to-rem(14);
      display: inline-block;
      margin-right: 15px;
      padding-right: 20px;
      border-right: 1px solid #333;
      line-height: 1em;

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }

      a {
        color: #333;
        text-decoration: none;

        &:hover, &:active, &:focus {
          text-decoration: underline;
        }
      }
    }
    .fb {
      position: absolute;
      right: 0;
      bottom: 0;

      @include max-screen($content-width) {
        right: 10px;
      }

      img {
        width: 36px;
      }
    }
  }

  .copyright {
    display: block;
    padding: 15px 0;
    background-color: #000;
    color: #fff;
    text-align: center;
  }
}
