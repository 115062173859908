@charset "UTF-8";

/* =============== LP =============== */
.apliko-package {
  padding-top: 40px;
  background-color: $colorPink;
  animation: bg-animation 12s ease-in-out -2s infinite alternate;

  @keyframes bg-animation {
    33%  { background-color: $colorPink; }
    66%  { background-color: #f3e434; }
    100% { background-color: #33d8e4; }
  }

  @include max-screen($breakpoint-medium) {
    padding-top: 40px;
  }
  @include max-screen($breakpoint-small) {
    text-align: center;
  }

  .inner {
    @extend %clearfix;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 850px;
  }
  .text {
    float: left;
    font-weight: bold;

    @include max-screen($breakpoint-small) {
      float: none;
    }

    .logo {
      margin: 30px 0 5px;
      width: 180px;

      @include max-screen($breakpoint-medium) {
        margin-top: 20px;
        width: 150px;
      }
      @include max-screen($breakpoint-small) {
        margin-top: 0;
      }
    }
    span {
      display: block;
    }
    .cms, .name {
      @include font-pixel-to-rem(30);

      @include max-screen($breakpoint-medium) {
        @include font-pixel-to-rem(22);
      }
    }
    .type {
      @include font-pixel-to-rem(18);

      @include max-screen($breakpoint-medium) {
        @include font-pixel-to-rem(14);
      }
    }
  }
  .visual {
    float: right;

    @include max-screen($breakpoint-small) {
      float: none;
    }

    img {
      width: 359px;

      @include max-screen($breakpoint-medium) {
        width: 300px;
      }
      @include max-screen(600px) {
        margin-top: 50px;
        width: 200px;
      }
      @include max-screen($breakpoint-small) {
        margin-top: 20px;
      }
    }
  }
}

.about-apliko {
  @extend %layoutCenter;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;

  @include max-screen($breakpoint-small) {
    padding-top: 30px;
  }

  h2 {
    @extend .mod-title-l1-01;
  }
  iframe {
    margin-top: 30px;
    border: 35px solid #000;

    @include max-screen($breakpoint-small) {
      margin-top: 20px;
    }
  }

  @include max-screen($breakpoint-large) {
    .fit-iframe {
      position: relative;
      padding-top: 56.25%;
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.section {
  padding-bottom: 30px;

  h3 {
    @extend .mod-title-l2-01;
  }
}

// お知らせ
.index-news {
  padding: 50px 0;
  background-color: #f2f2f2;

  .inner {
    @extend %layoutCenter;
    padding: 30px;
    max-width: 820px;
    background-color: #fff;

    @include max-screen(820px) {
      margin: 0 10px;
    }
    @include max-screen($breakpoint-small) {
      padding: 15px 10px 20px;
    }

    h3 {
      @include font-pixel-to-rem(26);
      margin-bottom: 20px;
      font-weight: bold;

      @include max-screen($breakpoint-small) {
        @include font-pixel-to-rem(20);
        margin-bottom: 5px;
      }
    }
  }
  .news-list {
    ul {
      margin-bottom: 30px;

      @include max-screen($breakpoint-small) {
        margin-bottom: 15px;
      }
    }
    li {
      display: table;
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid #aaa;

      a {
        color: #000;
        text-decoration: none;

        &:hover, &:active, &:focus {
          text-decoration: underline;
        }
      }
    }
    .date, .title {
      display: table-cell;
      vertical-align: top;
    }
    .date {
      width: 7em;
    }
    .more {
      a {
        position: relative;
        padding: 0 0 0 22px;
        vertical-align: middle;

        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 8px;
          height: 8px;
          border-top: 2px solid #000;
          border-right: 2px solid #000;
          vertical-align: middle;
          transform: rotate(45deg);
          content: "";
        }
      }
    }
  }
}

// 特徴
#feature {
  .inner {
    @extend %layoutCenter;

    @include max-screen($content-width) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .content {
    position: relative;
    margin-bottom: 40px;
    padding-top: 30px;
    background-color: #fff;
    box-shadow: 3px 3px 18px rgba(#000, .6);

    &::before {
      display: block;
      position: absolute;
      right: -10px;
      bottom: -10px;
      width: 100%;
      height: 100%;
      background-color: $colorPink;
      content: "";
      z-index: -1;
    }

    h4 {
      @include font-pixel-to-rem(22);
      position: relative;
      margin-bottom: 25px;
      padding: 0 10px 0 6em;

      @include max-screen($breakpoint-small) {
        @include font-pixel-to-rem(18);
        margin-bottom: 15px;
        padding: 2em 20px 0;
      }
    }
    .label {
      position: absolute;
      left: -10px;
      padding: .3em 1.5em;
      background-color: #000;
      color: #fff;

      @include max-screen($breakpoint-small) {
        top: -0.6em;
      }

      &::before {
      	position: absolute;
      	top: 100%;
        left: 0;
      	border-style: solid;
        border-width: 0 10px 10px 0;
      	border-color: transparent;
        border-right-color: $colorPink;
        content: "";
      }
    }
    .title {
      @include font-pixel-to-rem(28);
      font-weight: bold;

      @include max-screen($breakpoint-small) {
        @include font-pixel-to-rem(22);
      }
    }

    .inbox {
      padding: 0 50px;

      &.pb-small {

      }
      &.pb-medium {

      }

      @include max-screen($breakpoint-small) {
        padding: 0 20px;
      }

      p {
        margin-bottom: 20px;
      }
    }
    &:nth-child(1) .inbox {
      padding-bottom: 30px;

      @include max-screen($breakpoint-medium) {
        padding-bottom: 20px;
      }
      @include max-screen($breakpoint-small) {
        padding-bottom: 15px;
      }
    }
    &:nth-child(2) .inbox {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-bottom: 35px;
      @include max-screen($breakpoint-medium) {
        display: block;
      }
      .text {
        width: 43%;    // 400px / 930px
        @include max-screen($breakpoint-medium) {
          margin-bottom: 20px;
          width: 100%;
        }
        p {
          margin-bottom: 2em;
        }
        dl {
          font-weight: bold;
          dt {
            color: #e7388e;
            @include font-pixel-to-rem(18);
          }
          dd {
            &:not(:last-child) {
              margin-bottom: 1em;
            }
          }
        }
      }
      .figure {
        width: 53%;  // 490px / 930px
        @include max-screen($breakpoint-medium) {
          width: 100%;
        }
      }
    }
    &:nth-child(3) .inbox {
      padding-bottom: 50px;

      @include max-screen($breakpoint-medium) {
        padding-bottom: 35px;
      }
      @include max-screen($breakpoint-small) {
        padding-bottom: 25px;
      }
    }
    .figure {
      text-align: center;

      @include min-screen($content-width) {
        img {
          max-width: 870px;
        }
      }
    }
  }
}

// 機能
#function {
  background-color: #eee;

  .inner {
    @extend %layoutCenter;
    max-width: 820px;
  }
  .content {
    margin-bottom: 30px;
    padding: 30px;
    background-color: #fff;

    @include max-screen($breakpoint-small) {
      padding: 15px;
    }

    h4 {
      @include font-pixel-to-rem(26);
      margin-bottom: 20px;
      font-weight: bold;

      @include max-screen($breakpoint-small) {
        @include font-pixel-to-rem(20);
      }
    }
  }
}

// 価格表
#price {
  background-color: #eee;

  .inner {
    @extend %layoutCenter;
    margin-bottom: 30px;
  }

  .free {
    margin-bottom: 20px;
    text-align: center;
  }
  .table-scroll {
    overflow: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
    }
  }
  table {
    margin-bottom: 10px;

    th, td {
      padding: 20px;
      border: 1px solid #ddd;

      @include max-screen($breakpoint-small) {
        padding: 10px;
      }
    }
    th {
      white-space: nowrap;
    }
    thead {
      th {
        @include font-pixel-to-rem(18);
        border-left: 1px solid #ccc;
        background-color: #555;
        color: #fff;
        text-align: center;

        &:first-child {
          border-left: 1px solid #ddd;
        }

        .type {
          @include font-pixel-to-rem(13);
          display: block;
          font-weight: normal;
          line-height: 1;
        }
      }
    }
    tbody {
      th, td {
        @include font-pixel-to-rem(15);
        background-color: #fff;
      }
    }
  }
  .button {
    display: block;
    position: relative;
    top: 0;
    margin: 0 auto;
    padding: 10px 0;
    max-width: 150px;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 0 rgba(#000, 1);
    transition: all 0.1s linear;

    &:hover, &:focus {
      background-color: #444;
    }
    &:active {
      top: 3px;
      box-shadow: none;
    }
  }
  .attention {
    padding-top: 20px;
  }
}

// よくあるご質問
#faq {
  background-color: $colorLightPink;

  .inner {
    @extend %layoutCenter;
    margin-bottom: 30px;
  }

  .content {
    padding: 20px;
    border-bottom: 1px solid #eddae1;
    background-color: #fff;

    &:last-child {
      border-bottom: 0;
    }
  }
  .question, .answer {
    position: relative;
    padding-left: 2em;

    @include min-screen($breakpoint-small) {
      @include font-pixel-to-rem(18);
    }

    .q, .a {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold;
    }
    .a {
      color: #b1003a;
    }
  }
  .question {
    margin-bottom: 4px;
    font-weight: bold;
  }
}

// スタッフブログ
.index-blog {
  padding: 50px 0;

  .inner {
    @extend %layoutCenter;

    h3 {
      @include font-pixel-to-rem(32);
      margin-bottom: 30px;
      font-weight: bold;

      @include max-screen($breakpoint-small) {
        @include font-pixel-to-rem(26);
      }
    }
  }
  .blog-list {
    ul {
      @extend %clearfix;
      margin-bottom: 30px;
    }
    li {
      float: left;
      margin-right: 2.66%;
      width: 23%;

      @include min-screen($breakpoint-medium) {
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      @include max-screen($breakpoint-medium) {
        margin-right: 3%;
        margin-bottom: 20px;
        width: 48.5%;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      a {
        color: #000;
        text-decoration: none;

        &:hover, &:active, &:focus {
          text-decoration: underline;
        }
      }
    }
    .picture {
      margin-bottom: 18px;

      img {
        width: 100%;
      }
    }
    .title {
      margin-bottom: 8px;
      font-weight: bold;
    }
    .more {
      text-align: center;

      a {
        display: inline-block;
        padding: 10px 30px;
        border: 1px solid #000;
        color: #000;
        text-decoration: none;

        &:hover, &:active, &:focus {
          background-color: #ececec;
        }
      }
    }
  }
}

// お申し込み
#application {
  .inner {
    @extend %layoutCenter;
    max-width: 800px;
  }

  .content {
    margin-bottom: 30px;
    padding: 30px 80px;
    border: 1px solid #a7a7a7;

    @include max-screen($breakpoint-small) {
      padding: 10px;
    }
  }
  .contact-form {
    .contact-form-field {
      margin-bottom: 20px;
    }
    .form-element {
      margin-bottom: 4px;
    }
    .must {
      @extend .mod-annotation;
      margin-left: .5em;
    }

    input[type="text"], input[type="email"], textarea {
      padding: 8px;
      width: 100%;
      border: 0;
      border-radius: 5px;
      background-color: #eee;
    }
    textarea {
      min-height: 10em;
    }
  }
  .privacy {
    margin: 30px 0;
    text-align: center;
  }
  .contact-form-submit {
    text-align: center;

    input[type="submit"] {
      @include font-pixel-to-rem(18);
      position: relative;
      top: 0;
      padding: 8px 30px;
      border: 0;
      border-radius: 5px;
      background-color: #000;
      color: #fff;
      box-shadow: 0 3px 0 rgba(#000, 1);
      transition: all 0.1s linear;

      &:hover, &:focus {
        background-color: #444;
      }
      &:active {
        top: 3px;
        box-shadow: none;
      }
    }
  }
}

// 登録商標
.trademark {
  @extend %layoutCenter;
  margin-bottom: 10px;
}


/* =============== お知らせ =============== */
.news-entrylist {
  margin-bottom: 30px;
  padding-top: 9px;

  >li {
    margin-bottom: 11px;
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
    @include min-screen($breakpoint-small) {
      display: flex;
    }

    .pubdate {
      display: block;
      @include min-screen($breakpoint-small) {
        margin-right: 40px;
        width: 100px;
      }
    }
    .title {
      @include min-screen($breakpoint-small) {
        width: calc(100% - 140px);
      }
    }
  }
}


/* =============== ブログ =============== */
.blog-entrylist {
  margin-bottom: 30px;

  .content {
    @extend %clearfix;
    position: relative;
    padding: 19px;
    border: 1px solid #a6a6a6;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .text {
      width: 100%;
      @include min-screen($breakpoint-small) {
        float: right;
        width: percentage(470/740)
      }
    }
    .image {
      width: 100%;
      @include min-screen($breakpoint-small) {
        float: left;
        width: percentage(240/740)
      }
    }

    a {
      display: block;
      min-height: 160px;
      color: #000;
      text-decoration: none;
    }

    .title {
      @include font-pixel-to-rem(18);
      margin-bottom: 10px;
      font-weight: bold;
    }


    .foot {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between;
      @include max-screen($breakpoint-medium) {
        display: block;
      }
      .readmore {
        display: block;
        position: relative;
        right: -20px;
        margin: 13px 0 0 10px;
        text-align: right;
        @include max-screen($breakpoint-medium) {
          right: auto;
          text-align: left;
          margin: 13px 0 10px 0;
        }
        .inner {
          @include font-pixel-to-rem(14);
          display: inline-block;
          padding: 7px 19px;
          background-color: #000;
          line-height: 1;
          color: #fff;
          &::before {
            display: inline-block;
            content: "";
            position: relative;
            top: -1px;
            border-style: none solid solid none;
            border-color: transparent #fff #fff transparent;
            border-width: 0 1px 1px 0;
            margin-right: 7px;
            width: 6px;
            height: 6px;
            transform: rotate(-45deg);
          }
        }
      }

      .meta {
        @include font-pixel-to-rem(14);
      }
    }
  }
}


/* =============== 会員ページ =============== */
.Members {
  @at-root {
    .Members__contentsContainer {
      @extend %layoutCenter;
      > section {
        margin-bottom: 3em;
      }
      p {
        margin-bottom: 1em;
      }
      p,
      li,
      dd,
      td {
        >code {
          @extend .mod-code-01;
        }
      }
    }
    .Members__list {
      @extend .mod-list-01;
      li {
        font-weight: normal;
        @include font-pixel-to-rem(16);
      }
    }
  }
}

.MemberGreeting {
  margin-bottom: 30px;
  padding: 5px 0;
  background-color: #FBE1ED;
  .MemberGreeting__planinfo {
    display: flex;
    dt {
      &::after {
        content: "：";
        margin-right: 5px;
      }
    }
  }
}

.Members__signin {
  margin: 1em 0;
  text-align: center;
  a {
    @include font-pixel-to-rem(18);
    display: inline-block;
    position: relative;
    top: 0;
    padding: 8px 30px;
    border: 0;
    border-radius: 5px;
    background-color: $colorPink;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 2px 0 darken($colorPink, 10);
    transition: all 0.1s linear;

    &:hover, &:focus {
      background-color: lighten($colorPink, 3);
    }
    &:active {
      top: 2px;
      box-shadow: none;
      background-color: $colorPink;
    }
  }
}
