@charset "UTF-8";
article, aside, figcaption, figure,
header, main, nav, footer, section {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

/* basic */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body, header, footer, section {
  margin: 0; }

/* text */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal; }

dl, dd, p {
  margin: 0; }

ul, ol {
  margin: 0;
  padding: 0; }

li {
  list-style-type: none; }

b, strong {
  font-weight: bold; }

/* code/pre */
code, pre {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

/* sub/sup */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* visual */
img {
  border: 0;
  vertical-align: top; }

img[usemap] {
  height: auto;
  max-width: 100%;
  width: auto; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

/* other */
hr {
  display: block;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

mark {
  background: #ff0;
  color: #000; }

/* form */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  border: 0;
  padding: 0; }

button, input, select, textarea {
  /*font-family: inherit;*/
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

/* table */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  text-align: left; }

/* =============== variable =============== */
/* =============== mixin =============== */
/* =============== extend =============== */
.mod-layout-two-column:after, .apliko-package .inner:after, .index-blog .blog-list ul:after, .blog-entrylist .content:after {
  display: block;
  clear: both;
  content: ""; }

.mod-layout-two-column, .mod-layout-center, #header .inner, #footer .nav, .about-apliko, .index-news .inner, #feature .inner, #function .inner, #price .inner, #faq .inner, .index-blog .inner, #application .inner, .trademark, .Members__contentsContainer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1050px; }
  @media screen and (max-width: 1050px) {
    .mod-layout-two-column, .mod-layout-center, #header .inner, #footer .nav, .about-apliko, .index-news .inner, #feature .inner, #function .inner, #price .inner, #faq .inner, .index-blog .inner, #application .inner, .trademark, .Members__contentsContainer {
      padding-right: 10px;
      padding-left: 10px; } }

/* =============== base =============== */
body {
  position: relative;
  background-color: #fff;
  color: #000;
  font-family: 'Rounded Mplus 1c', 'Hiragino Kaku Gothic Pro', Meiryo, sans-serif;
  line-height: 1.5; }
  body * {
    box-sizing: border-box; }

a {
  color: #ad004a;
  text-decoration: underline; }
  a:hover, a:active {
    text-decoration: none; }

img {
  max-width: 100%; }

/* =============== レイアウト =============== */
.mod-layout-two-column {
  margin-bottom: 100px; }
  @media screen and (max-width: 768px) {
    .mod-layout-two-column {
      margin-bottom: 50px; } }
  .mod-layout-two-column .main-column {
    float: right;
    width: 74.28571%; }
    @media screen and (max-width: 768px) {
      .mod-layout-two-column .main-column {
        float: none;
        margin-bottom: 50px;
        width: 100%; } }
  .mod-layout-two-column .sub-column {
    float: left;
    width: 22.85714%; }
    @media screen and (max-width: 768px) {
      .mod-layout-two-column .sub-column {
        float: none;
        width: 100%; } }

.mod-entry-archive-links {
  margin-bottom: 20px;
  padding: 19px;
  border: 1px solid #a6a6a6; }
  .mod-entry-archive-links .title {
    font-size: 1.125rem;
    margin-bottom: 14px;
    padding-bottom: 8px;
    border-bottom: 1px solid #a6a6a6;
    font-weight: bold; }
  .mod-entry-archive-links a {
    color: #000;
    text-decoration: none; }
  .mod-entry-archive-links .list > li {
    font-size: 0.875rem; }
    .mod-entry-archive-links .list > li:not(:last-child) {
      margin-bottom: 9px; }
    .mod-entry-archive-links .list > li.change-year {
      margin-top: 14px;
      border-top: 1px solid #a6a6a6;
      padding-top: 14px; }

/* =============== タイトル =============== */
.mod-title-l1-01, .about-apliko h2 {
  font-size: 1.75rem;
  margin-bottom: 30px;
  font-weight: bold; }
  @media screen and (max-width: 480px) {
    .mod-title-l1-01, .about-apliko h2 {
      font-size: 1.375rem;
      margin-bottom: 20px; } }

.mod-title-l1-02 {
  font-size: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  font-weight: bold;
  text-align: center; }
  @media screen and (min-width: 481px) {
    .mod-title-l1-02 {
      font-size: 1.75rem;
      min-height: 110px; } }

.mod-title-l1-03 {
  font-size: 1.5rem;
  margin-bottom: 24px;
  padding-bottom: 13px;
  border-bottom: 2px solid #000;
  font-weight: bold; }

.mod-title-l1-04 {
  font-size: 1.25rem;
  margin-bottom: 15px;
  font-weight: bold; }

.mod-title-l2-01, .section h3 {
  font-size: 2rem;
  margin-bottom: 30px;
  padding: 16px 0;
  background-color: #f254a1;
  font-weight: bold;
  text-align: center; }
  @media screen and (max-width: 480px) {
    .mod-title-l2-01, .section h3 {
      font-size: 1.625rem;
      padding: 12px 0; } }

.mod-title-l2-02, .entry-content h2 {
  font-size: 1.5rem;
  margin: 2em 0 1em;
  padding: 2px 0 2px 17px;
  border-left: 8px solid #c53a7e;
  background-color: #eee;
  font-weight: bold; }
  .mod-title-l2-02:first-of-type, .entry-content h2:first-of-type {
    margin-top: 1.5em; }

.mod-title-l3-01 {
  font-size: 1.625rem;
  margin-bottom: 10px;
  font-weight: bold; }
  @media screen and (max-width: 480px) {
    .mod-title-l3-01 {
      font-size: 1.25rem; } }

.mod-title-l3-02, .entry-content h3 {
  font-size: 1.25rem;
  margin: 1.5em 0 1em;
  padding-bottom: 7px;
  border-bottom: 1px solid #000;
  font-weight: bold; }

.mod-title-l4-01 {
  font-weight: bold; }
  @media screen and (min-width: 481px) {
    .mod-title-l4-01 {
      font-size: 1.0625rem; } }

/* =============== テキスト =============== */
.mod-annotation, #application .contact-form .must {
  font-size: 1rem;
  color: #ad004a; }

.mod-text-indent {
  margin-left: 2.6em;
  text-indent: -2.6em; }

.mod-code-01, .entry-content p > code,
.entry-content li > code,
.entry-content dd > code,
.entry-content td > code, .Members__contentsContainer p > code,
.Members__contentsContainer li > code,
.Members__contentsContainer dd > code,
.Members__contentsContainer td > code {
  margin: 0 3px;
  padding: 3px 5px;
  border-radius: 2px;
  background-color: #f9f2f4;
  color: #c7254e;
  font-size: 90%; }

/* =============== リスト =============== */
.mod-list-01, .entry-content ul, .Members__list {
  margin-bottom: 20px; }
  .mod-list-01 li, .entry-content ul li, .Members__list li {
    position: relative;
    padding-left: 1em;
    font-weight: bold;
    line-height: 2; }
    @media screen and (min-width: 481px) {
      .mod-list-01 li, .entry-content ul li, .Members__list li {
        font-size: 1.125rem; } }
    .mod-list-01 li::before, .entry-content ul li::before, .Members__list li::before {
      position: absolute;
      top: 0;
      left: 0;
      color: #f254a1;
      content: "-"; }

.mod-list-02, .entry-content ol {
  margin-bottom: 20px; }
  .mod-list-02 > li, .entry-content ol > li {
    margin-left: 18px;
    list-style: decimal outside; }

/* =============== 汎用 =============== */
.mod-mb-30 {
  margin-bottom: 30px; }

.entry-meta {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px; }
  .entry-meta:first-child {
    margin-right: 10px; }
  .entry-meta:last-child {
    margin-left: 10px; }
  .entry-meta .date-and-category > time,
  .entry-meta .date-and-category > div {
    display: inline; }

.entry-share {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 25px; }
  .entry-share > div {
    margin-left: 10px; }
  .entry-share .fb-like span {
    vertical-align: top !important; }

.entry-content {
  line-height: 1.875; }
  .entry-content h4 {
    font-size: 1.125rem;
    margin: 1.5em 0 1.25em;
    padding-bottom: 3px;
    border-bottom: 1px dotted #999;
    font-weight: bold; }
  .entry-content p {
    margin-bottom: 1em; }
  .entry-content ul {
    margin-bottom: 1em; }
    .entry-content ul li {
      font-size: 1rem;
      font-weight: normal; }
  .entry-content pre[class="brush: plain;"] {
    overflow: auto;
    padding: 9px;
    border: 1px solid #e1e1e8;
    border-radius: 3px;
    background-color: #f7f7f9; }

.entry-nav-archive {
  font-size: 0.875rem;
  margin: 50px 0 30px;
  line-height: 1;
  position: relative;
  text-align: center; }
  .entry-nav-archive .index {
    margin-bottom: 20px; }
    @media screen and (min-width: 769px) {
      .entry-nav-archive .index {
        margin-bottom: 0; } }
    .entry-nav-archive .index a {
      display: inline-block;
      border: 1px solid #a6a6a6;
      padding: 8px 10px;
      width: 120px;
      color: #000;
      text-decoration: none; }
      @media screen and (min-width: 769px) {
        .entry-nav-archive .index a {
          padding: 8px 5.64103%;
          width: 23.07692%; } }
  .entry-nav-archive .pages > li {
    width: 120px; }
    @media screen and (min-width: 769px) {
      .entry-nav-archive .pages > li {
        width: 23.07692%; } }
  .entry-nav-archive .pages .prev,
  .entry-nav-archive .pages .next {
    display: inline-block;
    margin: 0 10px; }
    @media screen and (min-width: 769px) {
      .entry-nav-archive .pages .prev,
      .entry-nav-archive .pages .next {
        position: absolute;
        top: 0;
        display: block;
        margin: 0; } }
    .entry-nav-archive .pages .prev a,
    .entry-nav-archive .pages .next a {
      display: block;
      padding: 9px 5.64103%;
      background-color: #000;
      color: #fff;
      text-decoration: none; }
  @media screen and (min-width: 769px) {
    .entry-nav-archive .pages .prev {
      left: 0;
      margin-right: 11.53846%; } }
  .entry-nav-archive .pages .prev a::before {
    display: inline-block;
    content: "";
    border-style: solid none none solid;
    border-color: #fff transparent transparent #fff;
    border-width: 2px 0 0 2px;
    margin-right: 8px;
    width: 6px;
    height: 6px;
    transform: rotate(-45deg); }
  @media screen and (min-width: 769px) {
    .entry-nav-archive .pages .next {
      right: 0;
      margin-left: 11.53846%; } }
  .entry-nav-archive .pages .next a::after {
    display: inline-block;
    content: "";
    border-style: none solid solid none;
    border-color: transparent #fff #fff transparent;
    border-width: 0 2px 2px 0;
    margin-left: 8px;
    width: 6px;
    height: 6px;
    transform: rotate(-45deg); }

/* =============== header =============== */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  z-index: 100; }
  #header .inner {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px; }
  #header .logo a {
    display: flex;
    align-items: flex-end;
    text-decoration: none; }
  #header .logo .kana {
    margin: 0 0 10px 10px;
    color: #cc216c; }
    @media screen and (max-width: 480px) {
      #header .logo .kana {
        margin-bottom: 5px; } }
  #header .logo img {
    height: 55px; }
    @media screen and (max-width: 480px) {
      #header .logo img {
        height: 40px; } }
  #header .spmenu-toggle {
    display: block;
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 25px 0 0;
    width: 3.4em;
    height: 50px;
    border: 0;
    background: #fff;
    transition: all .4s; }
    @media screen and (min-width: 901px) {
      #header .spmenu-toggle {
        display: none; } }
    @media screen and (max-width: 480px) {
      #header .spmenu-toggle {
        top: 7px;
        right: 7px; } }
    @media screen and (max-width: 320px) {
      #header .spmenu-toggle {
        top: 5px;
        right: 5px; } }
    #header .spmenu-toggle .top, #header .spmenu-toggle .middle, #header .spmenu-toggle .bottom {
      position: absolute;
      top: 14px;
      left: .8em;
      width: 1.8em;
      height: 3px;
      background-color: #000;
      border-radius: 4px;
      transition: all .4s;
      transform: rotate(0deg); }
    #header .spmenu-toggle .top {
      transform: translateY(-8px); }
    #header .spmenu-toggle .bottom {
      transform: translateY(8px); }
    #header .spmenu-toggle.active .top {
      transform: rotate(-45deg) translateY(0); }
    #header .spmenu-toggle.active .middle {
      background-color: rgba(255, 255, 255, 0); }
    #header .spmenu-toggle.active .bottom {
      transform: rotate(45deg) translateY(0); }
    #header .spmenu-toggle .label {
      font-size: 0.75rem;
      display: block;
      color: #000;
      line-height: 1em; }

#nav-global a {
  display: block;
  color: #000;
  text-decoration: none; }

#nav-global .fb {
  position: absolute;
  top: 20px;
  right: 0; }
  @media screen and (max-width: 900px) {
    #nav-global .fb {
      right: 75px; } }
  @media screen and (max-width: 480px) {
    #nav-global .fb {
      top: 12px; } }
  #nav-global .fb img {
    width: 36px; }

@media screen and (min-width: 901px) {
  #nav-global {
    position: absolute;
    top: 0;
    right: 0; }
    #nav-global .menus {
      display: flex;
      padding-right: 60px;
      height: 75px; }
      #nav-global .menus li a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        width: 100%;
        height: 100%; }
        #nav-global .menus li a.active, #nav-global .menus li a:hover, #nav-global .menus li a:active, #nav-global .menus li a:focus {
          color: #ad004a; } }

@media screen and (max-width: 900px) {
  #nav-global .menus {
    margin-top: 10px; }
    #nav-global .menus > li:last-child a {
      border-bottom: 1px solid #ddd; }
    #nav-global .menus a {
      padding: 8px 10px;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      background-color: #eee; }
      #nav-global .menus a::before {
        margin-right: 8px;
        content: '>'; }
      #nav-global .menus a:hover, #nav-global .menus a:active {
        background-color: #f254a1;
        color: #fff; } }

#topicpath {
  font-size: 0.875rem;
  line-height: 1;
  background-color: #000;
  color: #fff; }
  #topicpath a {
    color: #fff;
    text-decoration: none; }
  #topicpath em {
    font-style: normal; }

/* =============== main =============== */
#main {
  margin-top: 110px; }
  .home #main {
    margin-top: 75px; }
  @media screen and (max-width: 480px) {
    #main {
      margin-top: 95px; }
      .home #main {
        margin-top: 60px; } }

/* =============== footer =============== */
#footer .nav {
  position: relative;
  margin-top: 45px;
  margin-bottom: 25px; }
  @media screen and (max-width: 1050px) {
    #footer .nav ul {
      padding-right: 60px; } }
  #footer .nav li {
    font-size: 0.875rem;
    display: inline-block;
    margin-right: 15px;
    padding-right: 20px;
    border-right: 1px solid #333;
    line-height: 1em; }
    #footer .nav li:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0; }
    #footer .nav li a {
      color: #333;
      text-decoration: none; }
      #footer .nav li a:hover, #footer .nav li a:active, #footer .nav li a:focus {
        text-decoration: underline; }
  #footer .nav .fb {
    position: absolute;
    right: 0;
    bottom: 0; }
    @media screen and (max-width: 1050px) {
      #footer .nav .fb {
        right: 10px; } }
    #footer .nav .fb img {
      width: 36px; }

#footer .copyright {
  display: block;
  padding: 15px 0;
  background-color: #000;
  color: #fff;
  text-align: center; }

/* =============== LP =============== */
.apliko-package {
  padding-top: 40px;
  background-color: #f254a1;
  animation: bg-animation 12s ease-in-out -2s infinite alternate; }

@keyframes bg-animation {
  33% {
    background-color: #f254a1; }
  66% {
    background-color: #f3e434; }
  100% {
    background-color: #33d8e4; } }
  @media screen and (max-width: 768px) {
    .apliko-package {
      padding-top: 40px; } }
  @media screen and (max-width: 480px) {
    .apliko-package {
      text-align: center; } }
  .apliko-package .inner {
    margin: 0 auto;
    padding: 0 30px;
    max-width: 850px; }
  .apliko-package .text {
    float: left;
    font-weight: bold; }
    @media screen and (max-width: 480px) {
      .apliko-package .text {
        float: none; } }
    .apliko-package .text .logo {
      margin: 30px 0 5px;
      width: 180px; }
      @media screen and (max-width: 768px) {
        .apliko-package .text .logo {
          margin-top: 20px;
          width: 150px; } }
      @media screen and (max-width: 480px) {
        .apliko-package .text .logo {
          margin-top: 0; } }
    .apliko-package .text span {
      display: block; }
    .apliko-package .text .cms, .apliko-package .text .name {
      font-size: 1.875rem; }
      @media screen and (max-width: 768px) {
        .apliko-package .text .cms, .apliko-package .text .name {
          font-size: 1.375rem; } }
    .apliko-package .text .type {
      font-size: 1.125rem; }
      @media screen and (max-width: 768px) {
        .apliko-package .text .type {
          font-size: 0.875rem; } }
  .apliko-package .visual {
    float: right; }
    @media screen and (max-width: 480px) {
      .apliko-package .visual {
        float: none; } }
    .apliko-package .visual img {
      width: 359px; }
      @media screen and (max-width: 768px) {
        .apliko-package .visual img {
          width: 300px; } }
      @media screen and (max-width: 600px) {
        .apliko-package .visual img {
          margin-top: 50px;
          width: 200px; } }
      @media screen and (max-width: 480px) {
        .apliko-package .visual img {
          margin-top: 20px; } }

.about-apliko {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center; }
  @media screen and (max-width: 480px) {
    .about-apliko {
      padding-top: 30px; } }
  .about-apliko iframe {
    margin-top: 30px;
    border: 35px solid #000; }
    @media screen and (max-width: 480px) {
      .about-apliko iframe {
        margin-top: 20px; } }
  @media screen and (max-width: 900px) {
    .about-apliko .fit-iframe {
      position: relative;
      padding-top: 56.25%;
      width: 100%; }
    .about-apliko iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

.section {
  padding-bottom: 30px; }

.index-news {
  padding: 50px 0;
  background-color: #f2f2f2; }
  .index-news .inner {
    padding: 30px;
    max-width: 820px;
    background-color: #fff; }
    @media screen and (max-width: 820px) {
      .index-news .inner {
        margin: 0 10px; } }
    @media screen and (max-width: 480px) {
      .index-news .inner {
        padding: 15px 10px 20px; } }
    .index-news .inner h3 {
      font-size: 1.625rem;
      margin-bottom: 20px;
      font-weight: bold; }
      @media screen and (max-width: 480px) {
        .index-news .inner h3 {
          font-size: 1.25rem;
          margin-bottom: 5px; } }
  .index-news .news-list ul {
    margin-bottom: 30px; }
    @media screen and (max-width: 480px) {
      .index-news .news-list ul {
        margin-bottom: 15px; } }
  .index-news .news-list li {
    display: table;
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid #aaa; }
    .index-news .news-list li a {
      color: #000;
      text-decoration: none; }
      .index-news .news-list li a:hover, .index-news .news-list li a:active, .index-news .news-list li a:focus {
        text-decoration: underline; }
  .index-news .news-list .date, .index-news .news-list .title {
    display: table-cell;
    vertical-align: top; }
  .index-news .news-list .date {
    width: 7em; }
  .index-news .news-list .more a {
    position: relative;
    padding: 0 0 0 22px;
    vertical-align: middle; }
    .index-news .news-list .more a::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 8px;
      height: 8px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      vertical-align: middle;
      transform: rotate(45deg);
      content: ""; }

@media screen and (max-width: 1050px) {
  #feature .inner {
    padding-right: 20px;
    padding-left: 20px; } }

#feature .content {
  position: relative;
  margin-bottom: 40px;
  padding-top: 30px;
  background-color: #fff;
  box-shadow: 3px 3px 18px rgba(0, 0, 0, 0.6); }
  #feature .content::before {
    display: block;
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 100%;
    height: 100%;
    background-color: #f254a1;
    content: "";
    z-index: -1; }
  #feature .content h4 {
    font-size: 1.375rem;
    position: relative;
    margin-bottom: 25px;
    padding: 0 10px 0 6em; }
    @media screen and (max-width: 480px) {
      #feature .content h4 {
        font-size: 1.125rem;
        margin-bottom: 15px;
        padding: 2em 20px 0; } }
  #feature .content .label {
    position: absolute;
    left: -10px;
    padding: .3em 1.5em;
    background-color: #000;
    color: #fff; }
    @media screen and (max-width: 480px) {
      #feature .content .label {
        top: -0.6em; } }
    #feature .content .label::before {
      position: absolute;
      top: 100%;
      left: 0;
      border-style: solid;
      border-width: 0 10px 10px 0;
      border-color: transparent;
      border-right-color: #f254a1;
      content: ""; }
  #feature .content .title {
    font-size: 1.75rem;
    font-weight: bold; }
    @media screen and (max-width: 480px) {
      #feature .content .title {
        font-size: 1.375rem; } }
  #feature .content .inbox {
    padding: 0 50px; }
    @media screen and (max-width: 480px) {
      #feature .content .inbox {
        padding: 0 20px; } }
    #feature .content .inbox p {
      margin-bottom: 20px; }
  #feature .content:nth-child(1) .inbox {
    padding-bottom: 30px; }
    @media screen and (max-width: 768px) {
      #feature .content:nth-child(1) .inbox {
        padding-bottom: 20px; } }
    @media screen and (max-width: 480px) {
      #feature .content:nth-child(1) .inbox {
        padding-bottom: 15px; } }
  #feature .content:nth-child(2) .inbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 35px; }
    @media screen and (max-width: 768px) {
      #feature .content:nth-child(2) .inbox {
        display: block; } }
    #feature .content:nth-child(2) .inbox .text {
      width: 43%; }
      @media screen and (max-width: 768px) {
        #feature .content:nth-child(2) .inbox .text {
          margin-bottom: 20px;
          width: 100%; } }
      #feature .content:nth-child(2) .inbox .text p {
        margin-bottom: 2em; }
      #feature .content:nth-child(2) .inbox .text dl {
        font-weight: bold; }
        #feature .content:nth-child(2) .inbox .text dl dt {
          color: #e7388e;
          font-size: 1.125rem; }
        #feature .content:nth-child(2) .inbox .text dl dd:not(:last-child) {
          margin-bottom: 1em; }
    #feature .content:nth-child(2) .inbox .figure {
      width: 53%; }
      @media screen and (max-width: 768px) {
        #feature .content:nth-child(2) .inbox .figure {
          width: 100%; } }
  #feature .content:nth-child(3) .inbox {
    padding-bottom: 50px; }
    @media screen and (max-width: 768px) {
      #feature .content:nth-child(3) .inbox {
        padding-bottom: 35px; } }
    @media screen and (max-width: 480px) {
      #feature .content:nth-child(3) .inbox {
        padding-bottom: 25px; } }
  #feature .content .figure {
    text-align: center; }
    @media screen and (min-width: 1051px) {
      #feature .content .figure img {
        max-width: 870px; } }

#function {
  background-color: #eee; }
  #function .inner {
    max-width: 820px; }
  #function .content {
    margin-bottom: 30px;
    padding: 30px;
    background-color: #fff; }
    @media screen and (max-width: 480px) {
      #function .content {
        padding: 15px; } }
    #function .content h4 {
      font-size: 1.625rem;
      margin-bottom: 20px;
      font-weight: bold; }
      @media screen and (max-width: 480px) {
        #function .content h4 {
          font-size: 1.25rem; } }

#price {
  background-color: #eee; }
  #price .inner {
    margin-bottom: 30px; }
  #price .free {
    margin-bottom: 20px;
    text-align: center; }
  #price .table-scroll {
    overflow: auto; }
    #price .table-scroll::-webkit-scrollbar {
      height: 5px; }
    #price .table-scroll::-webkit-scrollbar-track {
      background-color: #eee; }
    #price .table-scroll::-webkit-scrollbar-thumb {
      background-color: #aaa; }
  #price table {
    margin-bottom: 10px; }
    #price table th, #price table td {
      padding: 20px;
      border: 1px solid #ddd; }
      @media screen and (max-width: 480px) {
        #price table th, #price table td {
          padding: 10px; } }
    #price table th {
      white-space: nowrap; }
    #price table thead th {
      font-size: 1.125rem;
      border-left: 1px solid #ccc;
      background-color: #555;
      color: #fff;
      text-align: center; }
      #price table thead th:first-child {
        border-left: 1px solid #ddd; }
      #price table thead th .type {
        font-size: 0.8125rem;
        display: block;
        font-weight: normal;
        line-height: 1; }
    #price table tbody th, #price table tbody td {
      font-size: 0.9375rem;
      background-color: #fff; }
  #price .button {
    display: block;
    position: relative;
    top: 0;
    margin: 0 auto;
    padding: 10px 0;
    max-width: 150px;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 0 black;
    transition: all 0.1s linear; }
    #price .button:hover, #price .button:focus {
      background-color: #444; }
    #price .button:active {
      top: 3px;
      box-shadow: none; }
  #price .attention {
    padding-top: 20px; }

#faq {
  background-color: #fbe1ee; }
  #faq .inner {
    margin-bottom: 30px; }
  #faq .content {
    padding: 20px;
    border-bottom: 1px solid #eddae1;
    background-color: #fff; }
    #faq .content:last-child {
      border-bottom: 0; }
  #faq .question, #faq .answer {
    position: relative;
    padding-left: 2em; }
    @media screen and (min-width: 481px) {
      #faq .question, #faq .answer {
        font-size: 1.125rem; } }
    #faq .question .q, #faq .question .a, #faq .answer .q, #faq .answer .a {
      position: absolute;
      top: 0;
      left: 0;
      font-weight: bold; }
    #faq .question .a, #faq .answer .a {
      color: #b1003a; }
  #faq .question {
    margin-bottom: 4px;
    font-weight: bold; }

.index-blog {
  padding: 50px 0; }
  .index-blog .inner h3 {
    font-size: 2rem;
    margin-bottom: 30px;
    font-weight: bold; }
    @media screen and (max-width: 480px) {
      .index-blog .inner h3 {
        font-size: 1.625rem; } }
  .index-blog .blog-list ul {
    margin-bottom: 30px; }
  .index-blog .blog-list li {
    float: left;
    margin-right: 2.66%;
    width: 23%; }
    @media screen and (min-width: 769px) {
      .index-blog .blog-list li:nth-child(4n) {
        margin-right: 0; } }
    @media screen and (max-width: 768px) {
      .index-blog .blog-list li {
        margin-right: 3%;
        margin-bottom: 20px;
        width: 48.5%; }
        .index-blog .blog-list li:nth-child(2n) {
          margin-right: 0; } }
    .index-blog .blog-list li a {
      color: #000;
      text-decoration: none; }
      .index-blog .blog-list li a:hover, .index-blog .blog-list li a:active, .index-blog .blog-list li a:focus {
        text-decoration: underline; }
  .index-blog .blog-list .picture {
    margin-bottom: 18px; }
    .index-blog .blog-list .picture img {
      width: 100%; }
  .index-blog .blog-list .title {
    margin-bottom: 8px;
    font-weight: bold; }
  .index-blog .blog-list .more {
    text-align: center; }
    .index-blog .blog-list .more a {
      display: inline-block;
      padding: 10px 30px;
      border: 1px solid #000;
      color: #000;
      text-decoration: none; }
      .index-blog .blog-list .more a:hover, .index-blog .blog-list .more a:active, .index-blog .blog-list .more a:focus {
        background-color: #ececec; }

#application .inner {
  max-width: 800px; }

#application .content {
  margin-bottom: 30px;
  padding: 30px 80px;
  border: 1px solid #a7a7a7; }
  @media screen and (max-width: 480px) {
    #application .content {
      padding: 10px; } }

#application .contact-form .contact-form-field {
  margin-bottom: 20px; }

#application .contact-form .form-element {
  margin-bottom: 4px; }

#application .contact-form .must {
  margin-left: .5em; }

#application .contact-form input[type="text"], #application .contact-form input[type="email"], #application .contact-form textarea {
  padding: 8px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background-color: #eee; }

#application .contact-form textarea {
  min-height: 10em; }

#application .privacy {
  margin: 30px 0;
  text-align: center; }

#application .contact-form-submit {
  text-align: center; }
  #application .contact-form-submit input[type="submit"] {
    font-size: 1.125rem;
    position: relative;
    top: 0;
    padding: 8px 30px;
    border: 0;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    box-shadow: 0 3px 0 black;
    transition: all 0.1s linear; }
    #application .contact-form-submit input[type="submit"]:hover, #application .contact-form-submit input[type="submit"]:focus {
      background-color: #444; }
    #application .contact-form-submit input[type="submit"]:active {
      top: 3px;
      box-shadow: none; }

.trademark {
  margin-bottom: 10px; }

/* =============== お知らせ =============== */
.news-entrylist {
  margin-bottom: 30px;
  padding-top: 9px; }
  .news-entrylist > li {
    margin-bottom: 11px;
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa; }
    @media screen and (min-width: 481px) {
      .news-entrylist > li {
        display: flex; } }
    .news-entrylist > li .pubdate {
      display: block; }
      @media screen and (min-width: 481px) {
        .news-entrylist > li .pubdate {
          margin-right: 40px;
          width: 100px; } }
    @media screen and (min-width: 481px) {
      .news-entrylist > li .title {
        width: calc(100% - 140px); } }

/* =============== ブログ =============== */
.blog-entrylist {
  margin-bottom: 30px; }
  .blog-entrylist .content {
    position: relative;
    padding: 19px;
    border: 1px solid #a6a6a6; }
    .blog-entrylist .content:not(:last-child) {
      margin-bottom: 20px; }
    .blog-entrylist .content .text {
      width: 100%; }
      @media screen and (min-width: 481px) {
        .blog-entrylist .content .text {
          float: right;
          width: 63.51351%; } }
    .blog-entrylist .content .image {
      width: 100%; }
      @media screen and (min-width: 481px) {
        .blog-entrylist .content .image {
          float: left;
          width: 32.43243%; } }
    .blog-entrylist .content a {
      display: block;
      min-height: 160px;
      color: #000;
      text-decoration: none; }
    .blog-entrylist .content .title {
      font-size: 1.125rem;
      margin-bottom: 10px;
      font-weight: bold; }
    .blog-entrylist .content .foot {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .blog-entrylist .content .foot {
          display: block; } }
      .blog-entrylist .content .foot .readmore {
        display: block;
        position: relative;
        right: -20px;
        margin: 13px 0 0 10px;
        text-align: right; }
        @media screen and (max-width: 768px) {
          .blog-entrylist .content .foot .readmore {
            right: auto;
            text-align: left;
            margin: 13px 0 10px 0; } }
        .blog-entrylist .content .foot .readmore .inner {
          font-size: 0.875rem;
          display: inline-block;
          padding: 7px 19px;
          background-color: #000;
          line-height: 1;
          color: #fff; }
          .blog-entrylist .content .foot .readmore .inner::before {
            display: inline-block;
            content: "";
            position: relative;
            top: -1px;
            border-style: none solid solid none;
            border-color: transparent #fff #fff transparent;
            border-width: 0 1px 1px 0;
            margin-right: 7px;
            width: 6px;
            height: 6px;
            transform: rotate(-45deg); }
      .blog-entrylist .content .foot .meta {
        font-size: 0.875rem; }

/* =============== 会員ページ =============== */
.Members__contentsContainer > section {
  margin-bottom: 3em; }

.Members__contentsContainer p {
  margin-bottom: 1em; }

.Members__list li {
  font-weight: normal;
  font-size: 1rem; }

.MemberGreeting {
  margin-bottom: 30px;
  padding: 5px 0;
  background-color: #FBE1ED; }
  .MemberGreeting .MemberGreeting__planinfo {
    display: flex; }
    .MemberGreeting .MemberGreeting__planinfo dt::after {
      content: "：";
      margin-right: 5px; }

.Members__signin {
  margin: 1em 0;
  text-align: center; }
  .Members__signin a {
    font-size: 1.125rem;
    display: inline-block;
    position: relative;
    top: 0;
    padding: 8px 30px;
    border: 0;
    border-radius: 5px;
    background-color: #f254a1;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 2px 0 #ee2587;
    transition: all 0.1s linear; }
    .Members__signin a:hover, .Members__signin a:focus {
      background-color: #f362a9; }
    .Members__signin a:active {
      top: 2px;
      box-shadow: none;
      background-color: #f254a1; }
