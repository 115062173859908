@charset "UTF-8";

/* =============== レイアウト =============== */
.mod-layout-two-column {
  @extend %clearfix;
  @extend %layoutCenter;
  margin-bottom: 100px;
  @include max-screen($breakpoint-medium) {
    margin-bottom: 50px;
  }
  .main-column {
    float: right;
    width: percentage(780/1050);
    @include max-screen($breakpoint-medium) {
      float: none;
      margin-bottom: 50px;
      width: 100%;
    }
  }
  .sub-column {
    float: left;
    width: percentage(240/1050);
    @include max-screen($breakpoint-medium) {
      float: none;
      width: 100%;
    }
  }
}

.mod-entry-archive-links {
  margin-bottom: 20px;
  padding: 19px;
  border: 1px solid #a6a6a6;

  .title {
    @include font-pixel-to-rem(18);
    margin-bottom: 14px;
    padding-bottom: 8px;
    border-bottom: 1px solid #a6a6a6;
    font-weight: bold;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .list {
    >li {
      @include font-pixel-to-rem(14);
      &:not(:last-child) {
        margin-bottom: 9px;
      }
      &.change-year {
        margin-top: 14px;
        border-top: 1px solid #a6a6a6;
        padding-top: 14px;
      }
    }
  }
}


/* =============== タイトル =============== */
.mod-title-l1-01 {
  @include font-pixel-to-rem(28);
  margin-bottom: 30px;
  font-weight: bold;

  @include max-screen($breakpoint-small) {
    @include font-pixel-to-rem(22);
    margin-bottom: 20px;
  }
}

.mod-title-l1-02 {
  @include font-pixel-to-rem(22);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  font-weight: bold;
  text-align: center;

  @include min-screen($breakpoint-small) {
    @include font-pixel-to-rem(28);
    min-height: 110px;
  }

  // &::before {
  //   content: "";
  //   display: inline-block;
  //   min-height: 70px;
  //   visibility: hidden;
  //   @include min-screen($breakpoint-small) {
  //     min-height: 110px;
  //   }
  // }
}

.mod-title-l1-03 {
  @include font-pixel-to-rem(24);
  margin-bottom: 24px;
  padding-bottom: 13px;
  border-bottom: 2px solid #000;
  font-weight: bold;
}

.mod-title-l1-04 {
  @include font-pixel-to-rem(20);
  margin-bottom: 15px;
  font-weight: bold;
}

.mod-title-l2-01 {
  @include font-pixel-to-rem(32);
  margin-bottom: 30px;
  padding: 16px 0;
  background-color: $colorPink;
  font-weight: bold;
  text-align: center;

  @include max-screen($breakpoint-small) {
    @include font-pixel-to-rem(26);
    padding: 12px 0;
  }
}

.mod-title-l2-02 {
  @include font-pixel-to-rem(24);
  margin: 2em 0 1em;
  padding: 2px 0 2px 17px;
  border-left: 8px solid #c53a7e;
  background-color: #eee;
  font-weight: bold;
  &:first-of-type {
    margin-top: 1.5em;
  }
}

.mod-title-l3-01 {
  @include font-pixel-to-rem(26);
  margin-bottom: 10px;
  font-weight: bold;

  @include max-screen($breakpoint-small) {
    @include font-pixel-to-rem(20);
  }
}

.mod-title-l3-02 {
  @include font-pixel-to-rem(20);
  margin: 1.5em 0 1em;
  padding-bottom: 7px;
  border-bottom: 1px solid #000;
  font-weight: bold;
}

.mod-title-l4-01 {
  font-weight: bold;

  @include min-screen($breakpoint-small) {
    @include font-pixel-to-rem(17);
  }
}


/* =============== テキスト =============== */
.mod-annotation {
  @include font-pixel-to-rem(16);
  color: $colorDeepPink;
}
.mod-text-indent {
  margin-left: 2.6em;
  text-indent: -2.6em;
}

.mod-code-01 {
  margin: 0 3px;
  padding: 3px 5px;
  border-radius: 2px;
  background-color: #f9f2f4;
  color: #c7254e;
  font-size: 90%;
}


/* =============== リスト =============== */
.mod-list-01 {
  margin-bottom: 20px;

  li {
    position: relative;
    padding-left: 1em;
    font-weight: bold;
    line-height: 2;

    @include min-screen($breakpoint-small) {
      @include font-pixel-to-rem(18);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      color: $colorPink;
      content: "-";
    }
  }
}


.mod-list-02 {
  margin-bottom: 20px;

  >li {
    margin-left: 18px;
    list-style: decimal outside;
  }
}


/* =============== 汎用 =============== */
.mod-mb-30 { margin-bottom: 30px; }

.mod-layout-center {
  @extend %layoutCenter;
}

.entry-meta {
  @include font-pixel-to-rem(14);
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }

  .date-and-category {
    > time,
    > div {
      display: inline;
    }
  }
}

.entry-share {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 25px;
  >div {
    margin-left: 10px;
  }
  .fb-like span {
    vertical-align: top !important;
  }
}

.entry-content {
  // @extend %layoutCenter;
  // padding-bottom: 30px;

  // &:last-child {
  //   margin-bottom: 20px;
  // }

  // .mod-title-l3-01 {
  //   margin-top: 30px;
  // }
  line-height: 1.875;
  h2 {
    @extend .mod-title-l2-02;
  }
  h3 {
    @extend .mod-title-l3-02;
  }
  h4 {
    @include font-pixel-to-rem(18);
    margin: 1.5em 0 1.25em;
    padding-bottom: 3px;
    border-bottom: 1px dotted #999;
    font-weight: bold;
  }
  p {
    margin-bottom: 1em;
  }
  ul {
    @extend .mod-list-01;
    margin-bottom: 1em;

    li {
      @include font-pixel-to-rem(16);
      font-weight: normal;
    }
  }
  ol {
    @extend .mod-list-02;
  }
  // dt, dd {
  //   display: table-cell;
  // }
  // dt {
  //   white-space: nowrap;
  //   &:after {
  //     margin: 0 10px;
  //     content: ":";
  //   }
  // }
  p,
  li,
  dd,
  td {
    >code {
      @extend .mod-code-01;
    }
  }
  pre[class="brush: plain;"] {
    overflow: auto;
    padding: 9px;
    border: 1px solid #e1e1e8;
    border-radius: 3px;
    background-color: #f7f7f9;
  }
}

.entry-nav-archive {
  @include font-pixel-to-rem(14);
  margin: 50px 0 30px;
  line-height: 1;
  position: relative;
  text-align: center;
  .index {
    margin-bottom: 20px;
    @include min-screen($breakpoint-medium) {
      margin-bottom: 0;
    }
    a {
      display: inline-block;
      border: 1px solid #a6a6a6;
      padding: 8px 10px;
      width: 120px;
      color: #000;
      text-decoration: none;
      @include min-screen($breakpoint-medium) {
        padding: 8px percentage(44/780);
        width: percentage(180/780);
      }
    }
  }

  .pages {
    >li {
      width: 120px;
      @include min-screen($breakpoint-medium) {
        width: percentage(180/780);
      }
    }

    .prev,
    .next {
      display: inline-block;
      margin: 0 10px;
      @include min-screen($breakpoint-medium) {
        position: absolute;
        top: 0;
        display: block;
        margin: 0;
      }
      a {
        display: block;
        padding: 9px percentage(44/780);
        background-color: #000;
        color: #fff;
        text-decoration: none;
      }
    }
    .prev {
      @include min-screen($breakpoint-medium) {
        left: 0;
        margin-right: percentage(90/780);
      }
      a {
        &::before {
          display: inline-block;
          content: "";
          border-style: solid none none solid;
          border-color: #fff transparent transparent #fff;
          border-width: 2px 0 0 2px;
          margin-right: 8px;
          width: 6px;
          height: 6px;
          transform: rotate(-45deg);
        }
      }
    }
    .next {
      @include min-screen($breakpoint-medium) {
        right: 0;
        margin-left: percentage(90/780);
      }
      a {
        &::after {
          display: inline-block;
          content: "";
          border-style: none solid solid none;
          border-color: transparent #fff #fff transparent;
          border-width: 0 2px 2px 0;
          margin-left: 8px;
          width: 6px;
          height: 6px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
